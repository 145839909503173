<template>
  <div class="unsupportedb_maincontainer ">
    <div class="unsupport_box d-flex justify-content-center">
      <img src="../assets/images/unsupported.svg" alt="" />
    </div>
    <div class="unsupport_text d-flex justify-content-center">
      Unsupported Browser
    </div>
    <div class="unsupport_text1 d-flex justify-content-center">
      You’re using an unsupported web browser. Please use the Google Chrome web
      browser instead.
    </div>
    <div class="unsupport_gchrome d-flex justify-content-center mt-3">
      <img src="../assets/images/gchrome.svg" alt="" />
    </div>
    <div class="unsupport_text1 d-flex justify-content-center mt-2">
      Google Chrome
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
  beforeRouteLeave(to, from, next) {    
next(false);
  },
};
</script>

<style>
.unsupportedb_maincontainer {
  background-size: cover !important;
  background-image: url(/img/agenttextcard.c3035ebf.png);
  background-repeat: no-repeat;
}
.unsupport_box {
  margin-top: 150px;
}
.unsupport_text {
  margin-top: 40px;
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  /* identical to box height */

  color: #696969;
}
.unsupport_text1 {
  margin-top: 33px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #696969;
}
</style>
